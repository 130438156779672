import {
  Spaceable
} from "./chunk.LDSR6LCO.js";
import {
  customElement
} from "./chunk.QAGUCU4C.js";
import {
  ChangesBackgroundColor
} from "./chunk.LI7D7EGU.js";
import {
  ke,
  r as r2
} from "./chunk.WKUNRGJV.js";
import {
  n
} from "./chunk.BBN2ZB42.js";
import {
  i,
  r
} from "./chunk.FAJKIEWS.js";
import {
  __decorateClass
} from "./chunk.I4IFG4JG.js";

// src/components/jb-first-level-teaser/jb-first-level-teaser.scss?inline
var jb_first_level_teaser_default = i`*,
*::before,
*::after {
  box-sizing: border-box;
}

div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
ol,
ul,
li,
form,
legend,
label,
table,
header,
footer,
nav,
section,
figure {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

/* COLOR SETTINGS */
/* shadows */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
/* stylelint-disable */
/* stylelint-enable */
:host {
  --spacing-none: 0;
  --spacing-base: 4px;
  --spacing-3xs: 8px;
  --spacing-2xs: 12px;
  --spacing-xs: 16px;
  --spacing-s: 20px;
  --spacing-sm: 20px;
  --spacing-m: 24px;
  --spacing-lm: 28px;
  --spacing-l: 32px;
  --spacing-xl: 32px;
  --spacing-2xl: 36px;
  --spacing-3xl: 36px;
  --spacing-4xl: 72px;
  --spacing-small: var(--spacing-lm);
  --spacing-medium: var(--spacing-3xl);
  --spacing-big: var(--spacing-4xl);
  --spacing-gap: 16px;
}

@media screen and (width >= 1281px) {
  :host {
    --spacing-sm: 24px;
    --spacing-m: 32px;
    --spacing-lm: 36px;
    --spacing-l: 40px;
    --spacing-xl: 48px;
    --spacing-2xl: 64px;
    --spacing-3xl: 72px;
    --spacing-4xl: 144px;
    --spacing-small: var(--spacing-lm);
    --spacing-medium: var(--spacing-3xl);
    --spacing-big: var(--spacing-4xl);
    --spacing-gap: 40px;
  }
}
/* special measurements */
:host {
  color: var(--host-color, #141e55);
}

.container {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 1281px) {
  .container {
    margin-bottom: 90px;
  }
}

jb-section-headline {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0 0;
  width: 100%;
  padding-bottom: 50px;
}
@media (min-width: 600px) {
  jb-section-headline {
    padding-bottom: 54px;
  }
}
@media (min-width: 1281px) {
  jb-section-headline {
    padding-bottom: 70px;
  }
}

::slotted(jb-first-level-teaser-item:nth-child(1)) {
  --lottie-player-padding: 32px 45px 33px 29px;
}
@media (min-width: 960px) {
  ::slotted(jb-first-level-teaser-item:nth-child(1)) {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
    transform: translateY(87px);
  }
}
@media (min-width: 1281px) {
  ::slotted(jb-first-level-teaser-item:nth-child(1)) {
    transform: translateY(106px);
  }
}

::slotted(jb-first-level-teaser-item:nth-child(2)) {
  --lottie-player-padding: 10px 0 0;
}
@media (min-width: 960px) {
  ::slotted(jb-first-level-teaser-item:nth-child(2)) {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
    transform: translateY(17px) translateX(20px);
  }
}
@media (min-width: 1281px) {
  ::slotted(jb-first-level-teaser-item:nth-child(2)) {
    margin-left: 0%;
    transform: translateY(-4px) translateX(40px);
  }
}
@media (min-width: 1437px) {
  ::slotted(jb-first-level-teaser-item:nth-child(2)) {
    max-width: 47%;
  }
}

::slotted(jb-first-level-teaser-item:nth-child(3)) {
  --lottie-player-padding: 0 0 0 10px;
}
@media (min-width: 960px) {
  ::slotted(jb-first-level-teaser-item:nth-child(3)) {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 20px;
    width: 50%;
    margin-left: 25%;
    transform: translateY(13px);
  }
}
@media (min-width: 1281px) {
  ::slotted(jb-first-level-teaser-item:nth-child(3)) {
    margin-left: 0%;
    transform: translateY(82px) translateX(292px);
  }
}`;

// src/components/jb-first-level-teaser/jb-first-level-teaser.ts
var JbFirstLevelTeaser = class extends Spaceable(ChangesBackgroundColor(r2)) {
  _getContentSpacingProperties() {
    return { colsMdUp: "none" };
  }
  render() {
    return ke`<div class="container">
      <jb-section-headline .headline=${this.title}></jb-section-headline>
      <slot></slot>
    </div>`;
  }
};
JbFirstLevelTeaser.styles = r(jb_first_level_teaser_default);
__decorateClass([
  n()
], JbFirstLevelTeaser.prototype, "title", 2);
JbFirstLevelTeaser = __decorateClass([
  customElement("jb-first-level-teaser")
], JbFirstLevelTeaser);

export {
  JbFirstLevelTeaser
};
